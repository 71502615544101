/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation, withRouter } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import { OverlayPanel } from 'primereact/overlaypanel';
import { isEmpty } from 'lodash';
import { Growl } from 'primereact/growl';

import {
  // useNewChatMessage,
  useUser,
} from '../store/hooks/hooks';
import CONSTANTS from '../constants/constant';
import API from '../services/API';

import './Header.scss';
import UserProfileIcon from '../statics/images/profile_icon.png';
import Logo from '../statics/images/logo_wobg.png';
// import AudioMessage from '../statics/sounds/new-mess.mp3';
import { config } from '../config';

function Header() {
  const [unread, setUnread] = useState<number>(0);
  const [pendingApprovalCount, setPendingApprovalCount] = useState<number>(0);

  const userProfileRef = useRef<OverlayPanel>(null);
  const growlRef = useRef<Growl>(null);

  const user = useUser();
  const location = useLocation();
  // const newChatMessage = useNewChatMessage();

  const handleShowProfile = (e: any) => {
    if (userProfileRef.current) {
      userProfileRef.current.toggle(e);
    }
  };

  const handleHideProfile = () => {
    if (userProfileRef.current) {
      userProfileRef.current.hide();
    }
  };

  // const handleShowGrowl = () => {
  //   if (growlRef.current) {
  //     growlRef.current.show({ severity: 'info', summary: 'Thông báo', detail: 'Chức năng đang được phát triển' });
  //   }
  // };

  const getMessageCount = async () => {
    const result = await API.fetchUnreadMessageCount();
    if (result.success) {
      const unreadMessage = result.data;
      if (!isEmpty(unreadMessage)) {
        let totalNewMsg = 0;
        for (const groupID of Object.keys(unreadMessage.internal)) {
          totalNewMsg += unreadMessage.internal[groupID];
        }
        for (const groupID of Object.keys(unreadMessage.driver)) {
          totalNewMsg += unreadMessage.driver[groupID];
        }
        for (const groupID of Object.keys(unreadMessage.ctv)) {
          totalNewMsg += unreadMessage.ctv[groupID];
        }
        for (const groupID of Object.keys(unreadMessage.customer)) {
          totalNewMsg += unreadMessage.customer[groupID];
        }
        for (const groupID of Object.keys(unreadMessage.staff)) {
          totalNewMsg += unreadMessage.staff[groupID];
        }
        setUnread(totalNewMsg);
      }
    }
  };

  const getPendingApprovalCount = async () => {
    const response = await API.getPendingOwnerCount();
    if (response) {
      setPendingApprovalCount(response.data);
    }
  };

  useEffect(() => {
    getMessageCount();
    getPendingApprovalCount();
  }, []);

  return (
    <Navbar className="header" expand="lg">
      <Navbar.Brand href="/">
        <img src={Logo} alt="logo" className="logo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto" activeKey={location.pathname}>
          {user.hasVicMenu(CONSTANTS.VIC_MENU_OPTIONS.DS_TAI_XE) && <Nav.Link href="/tai-xe">DS TÀI XẾ</Nav.Link>}
          {user.hasVicMenu(CONSTANTS.VIC_MENU_OPTIONS.XE_DAT_HANG) && (
            <Nav.Link href="/xe-dat-hang">XE ĐẶT HÀNG</Nav.Link>
          )}
          {user.hasVicMenu(CONSTANTS.VIC_MENU_OPTIONS.DS_CHANH) && <Nav.Link href="/chanh">DS CHÀNH</Nav.Link>}
          {user.hasVicMenu(CONSTANTS.VIC_MENU_OPTIONS.CHANH_DAT_HANG) && (
            <Nav.Link href="/chanh-dat-hang">CHÀNH ĐẶT HÀNG</Nav.Link>
          )}
          {user.hasVicMenu(CONSTANTS.VIC_MENU_OPTIONS.DS_CHU_HANG) && <Nav.Link href="/chu-hang">DS CHỦ HÀNG</Nav.Link>}
          {user.hasVicMenu(CONSTANTS.VIC_MENU_OPTIONS.CHU_DAT_HANG) && (
            <Nav.Link href="/chu-dat-hang">
              <div className="pending-owner-header">
                CHỦ ĐẶT HÀNG
                {pendingApprovalCount > 0 && <p className="pending-owner-badges">{pendingApprovalCount}</p>}
              </div>
            </Nav.Link>
          )}
          {user.hasVicMenu(CONSTANTS.VIC_MENU_OPTIONS.VAN_HANH_DON_HANG) && (
            <Nav.Link href="/van-hanh">VẬN HÀNH ĐƠN HÀNG</Nav.Link>
          )}
          {user.hasVicMenu(CONSTANTS.VIC_MENU_OPTIONS.HOP_DONG_DON_HANG) && (
            <Nav.Link href="/hop-dong">HỢP ĐỒNG ĐƠN HÀNG</Nav.Link>
          )}
          {user.hasVicMenu(CONSTANTS.VIC_MENU_OPTIONS.THANH_TOAN_CHUYEN_KHOAN) && (
            <Nav.Link href="/thanh-toan">THANH TOÁN CHUYỂN KHOẢN</Nav.Link>
          )}
          {user.hasVicMenu(CONSTANTS.VIC_MENU_OPTIONS.KIEM_SOAT_DON_HANG) && (
            <Nav.Link href="/kiem-soat-dh">KIỂM SOÁT ĐƠN HÀNG</Nav.Link>
          )}
          {user.hasVicMenu(CONSTANTS.VIC_MENU_OPTIONS.THAM_KHAO_GIA) && (
            <Nav.Link href="/tham-khao-gia">THAM KHẢO GIÁ</Nav.Link>
          )}
          {/* <Nav.Link href="/don-hang-nhanh-v1">ĐƠN HÀNG NHANH</Nav.Link> */}
          {user.hasVicMenu(CONSTANTS.VIC_MENU_OPTIONS.DON_HANG_NHANH) && (
            <Nav.Link href="/don-hang-nhanh">ĐƠN HÀNG NHANH</Nav.Link>
          )}
          {user.hasVicMenu(CONSTANTS.VIC_MENU_OPTIONS.KY_QUY) && <Nav.Link href="/ky-quy">KÝ QUỸ</Nav.Link>}
          {user.hasVicMenu(CONSTANTS.VIC_MENU_OPTIONS.DS_BAO_HIEM) && <Nav.Link href="/bao-hiem">DS BẢO HIỂM</Nav.Link>}
          {user.hasVicMenu(CONSTANTS.VIC_MENU_OPTIONS.CSKH_BAO_HIEM) && (
            <Nav.Link href="/bao-hiem-sap-het-han">CSKH BẢO HIỂM</Nav.Link>
          )}
          {user.hasVicMenu(CONSTANTS.VIC_MENU_OPTIONS.DON_HANG_BAO_HIEM) && (
            <Nav.Link href="/don-hang-bao-hiem">ĐƠN HÀNG BẢO HIỂM</Nav.Link>
          )}
          {/* <Nav.Link href="/tk-txe">APP TÀI XẾ</Nav.Link>
          <Nav.Link href="/tk-ch">APP CHỦ HÀNG</Nav.Link> */}
          {user.hasVicMenu(CONSTANTS.VIC_MENU_OPTIONS.PHAN_VIEC_CHU_HANG) && (
            <Nav.Link href="/phan-viec-chu-hang">PHÂN VIỆC CHỦ HÀNG</Nav.Link>
          )}
          {/* <Nav.Link href="/phan-viec-tai-xe">PHÂN VIỆC TÀI XẾ</Nav.Link> */}
          {user.hasVicMenu(CONSTANTS.VIC_MENU_OPTIONS.TH_GIAN_LAM_VIEC) && (
            <Nav.Link href="/working-time">TH/GIAN LÀM VIỆC</Nav.Link>
          )}
          {user.hasVicMenu(CONSTANTS.VIC_MENU_OPTIONS.CHAT) && (
            <Nav.Link href={`${config.webChatUrl}/chat`} target="_blank">
              CHAT
            </Nav.Link>
          )}
          {user.hasVicMenu(CONSTANTS.VIC_MENU_OPTIONS.QUAN_LY_FILE) && (
            <Nav.Link href="/quan-ly-file">QUẢN LÝ FILE</Nav.Link>
          )}
          {user.hasProject(CONSTANTS.PROJECTS.PROJECT_ONE) && <Nav.Link href="/projectone">PROJECT ONE</Nav.Link>}
          <Nav.Item className="d-block d-lg-none">
            <hr />
          </Nav.Item>
          {user.hasVicMenu(CONSTANTS.VIC_MENU_OPTIONS.BAO_CAO_THONG_KE_CONG_VIEC) && (
            <Nav.Link href="/thong-ke" className="d-block d-lg-none">
              BÁO CÁO THỐNG KÊ CÔNG VIỆC
            </Nav.Link>
          )}
          {user.hasRole(CONSTANTS.PPKD_ROLES.XEM_THONG_KE_CUOC_GOI) && (
            <>
              <Nav.Link href="/tk-cuoc-goi" className="d-block d-lg-none">
                BÁO CÁO THỐNG KÊ CUỘC GỌI
              </Nav.Link>
            </>
          )}
          {(user.hasRole(CONSTANTS.SYSTEM_ROLES.QUAN_LY_GIO_CONG) || user.hasSalaryRole()) && (
            <>
              <Nav.Link href="/admin-working-time" className="d-block d-lg-none">
                QUẢN LÝ GIỜ LÀM VIỆC CỦA NHÂN VIÊN
              </Nav.Link>
            </>
          )}
          {user.hasRole(CONSTANTS.MANAGEMENT_ROLES.DUA_SDT_NHAN_VIEN_LEN_TREN_APP) && (
            <Nav.Link href="/app-phones" className="d-block d-lg-none">
              QUẢN LÝ SỐ ĐIỆN THOẠI TRÊN APP
            </Nav.Link>
          )}
          {user.isAdmin() && (
            <Nav.Link href="/tk-web" className="d-block d-lg-none">
              QUẢN LÝ TÀI KHOẢN WEB
            </Nav.Link>
          )}
          {user.hasVicMenu(CONSTANTS.VIC_MENU_OPTIONS.PHAP_CHE) && (
            <Nav.Link href="/phap-che" className="d-block d-lg-none">
              PHÁP CHẾ
            </Nav.Link>
          )}
          {(user.hasRole(CONSTANTS.SECRETARY_ROLES.QUAN_LY_KE_TOAN) ||
            user.hasRole(CONSTANTS.SECRETARY_ROLES.QUYEN_DUYET_CHI_TIEN_GDDA) ||
            user.hasRole(CONSTANTS.SECRETARY_ROLES.QUYEN_DUYET_CHI_TIEN_KTNB) ||
            user.hasRole(CONSTANTS.SECRETARY_ROLES.QUYEN_DUYET_CHI_TIEN_KTT) ||
            user.hasRole(CONSTANTS.SECRETARY_ROLES.QUYEN_DUYET_CHI_TIEN_TPKD)) && (
            <Nav.Link href="/ke-toan-chi" className="d-block d-lg-none">
              TOÁN CHI
            </Nav.Link>
          )}
          {user.hasRole(CONSTANTS.CHAT_ROLES.QUAN_LY_NHOM_CHAT_NOI_BO) && (
            <Nav.Link href="/quan-ly-nhom-chat" className="d-block d-lg-none">
              QUẢN LÝ NHÓM CHAT
            </Nav.Link>
          )}
          {user.hasRole(CONSTANTS.MANAGEMENT_ROLES.CAI_DAT_PHAN_DON) && (
            <Nav.Link href="/cai-dat-phan-don" className="d-block d-lg-none">
              CÀI ĐẶT PHÂN CHIA ĐƠN HÀNG
            </Nav.Link>
          )}
          {user.hasRole(CONSTANTS.MANAGEMENT_ROLES.DUA_SDT_NHAN_VIEN_LEN_TREN_APP) && (
            <Nav.Link href="/app-phones" className="d-block d-lg-none">
              QUẢN LÝ SỐ ĐIỆN THOẠI TRÊN APP
            </Nav.Link>
          )}
          <Nav.Link href="/profile" className="d-block d-lg-none">
            TÀI KHOẢN
          </Nav.Link>
          <Nav.Link href="/logout" className="d-block d-lg-none">
            ĐĂNG XUẤT
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
      {user && !isEmpty(user.token) ? (
        <>
          <div className="user-profile-icon d-none d-lg-block" id="user">
            <span onClick={handleShowProfile}>
              <img src={UserProfileIcon} alt="profile" />
            </span>
          </div>
          <OverlayPanel ref={userProfileRef} appendTo={document.body} className="user-profile-overlay">
            <div className="menu-header wrap-text">{user.email}</div>
            <div className="menu-item">
              <NavLink to="/thong-ke" className="item-group" onClick={handleHideProfile}>
                <i className="pi pi-briefcase" />
                <span>BÁO CÁO THỐNG KÊ CÔNG VIỆC</span>
              </NavLink>
            </div>
            {user.hasRole(CONSTANTS.PPKD_ROLES.XEM_THONG_KE_CUOC_GOI) && (
              <div className="menu-item">
                <NavLink to="/tk-cuoc-goi" className="item-group" onClick={handleHideProfile}>
                  <i className="pi pi-list" />
                  <span>BÁO CÁO THỐNG KÊ CUỘC GỌI</span>
                </NavLink>
              </div>
            )}
            {(user.hasRole(CONSTANTS.SYSTEM_ROLES.QUAN_LY_GIO_CONG) || user.hasSalaryRole()) && (
              <>
                <div className="menu-item">
                  <NavLink to="/admin-working-time" className="item-group" onClick={handleHideProfile}>
                    <i className="pi pi-clock" />
                    <span>QUẢN LÝ GIỜ LÀM VIỆC CỦA NHÂN VIÊN</span>
                  </NavLink>
                </div>
              </>
            )}
            {user.isAdmin() && (
              <div className="menu-item">
                <NavLink to="/tk-web" className="item-group" onClick={handleHideProfile}>
                  <i className="pi pi-user" />
                  <span>QUẢN LÝ TÀI KHOẢN WEB</span>
                </NavLink>
              </div>
            )}
            <div className="menu-item">
              <NavLink to="/phap-che" className="item-group" onClick={handleHideProfile}>
                <i className="pi pi-lock" />
                <span>PHÁP CHẾ</span>
              </NavLink>
            </div>
            {(user.hasRole(CONSTANTS.SECRETARY_ROLES.QUAN_LY_KE_TOAN) ||
              user.hasRole(CONSTANTS.SECRETARY_ROLES.QUYEN_DUYET_CHI_TIEN_GDDA) ||
              user.hasRole(CONSTANTS.SECRETARY_ROLES.QUYEN_DUYET_CHI_TIEN_KTNB) ||
              user.hasRole(CONSTANTS.SECRETARY_ROLES.QUYEN_DUYET_CHI_TIEN_KTT) ||
              user.hasRole(CONSTANTS.SECRETARY_ROLES.QUYEN_DUYET_CHI_TIEN_TPKD)) && (
              <div className="menu-item">
                <NavLink to="/ke-toan-chi" className="item-group" onClick={handleHideProfile}>
                  <i className="pi pi-money-bill" />
                  <span>KẾ TOÁN CHI</span>
                </NavLink>
              </div>
            )}
            {user.hasRole(CONSTANTS.CHAT_ROLES.QUAN_LY_NHOM_CHAT_NOI_BO) && (
              <div className="menu-item">
                <NavLink to="/quan-ly-nhom-chat" className="item-group" onClick={handleHideProfile}>
                  <i className="pi pi-cloud" />
                  <span>QUẢN LÝ NHÓM CHAT</span>
                </NavLink>
              </div>
            )}
            {user.hasRole(CONSTANTS.MANAGEMENT_ROLES.CAI_DAT_PHAN_DON) && (
              <div className="menu-item">
                <NavLink to="/cai-dat-phan-don" className="item-group" onClick={handleHideProfile}>
                  <i className="pi pi-comments" />
                  <span>CÀI ĐẶT PHÂN CHIA ĐƠN HÀNG</span>
                </NavLink>
              </div>
            )}
            {user.hasRole(CONSTANTS.MANAGEMENT_ROLES.DUA_SDT_NHAN_VIEN_LEN_TREN_APP) && (
              <div className="menu-item">
                <NavLink to="/app-phones" className="item-group" onClick={handleHideProfile}>
                  <i className="pi pi-mobile" />
                  <span>QUẢN LÝ SỐ ĐIỆN THOẠI TRÊN APP</span>
                </NavLink>
              </div>
            )}
            <div className="menu-item">
              <NavLink to="/profile" className="item-group" onClick={handleHideProfile}>
                <i className="pi pi-user" />
                <span>TÀI KHOẢN</span>
              </NavLink>
            </div>
            <div className="menu-item">
              <NavLink to="/logout" className="item-group" onClick={handleHideProfile}>
                <i className="pi pi-sign-out" />
                <span>ĐĂNG XUẤT</span>
              </NavLink>
            </div>
          </OverlayPanel>
          <div className="chats-icon d-none d-lg-block">
            <a className="pi pi-comments" href={`${config.webChatUrl}/chat`} target="_blank">
              {unread > 0 && <span className="chat-badges">{unread > 99 ? '99+' : unread}</span>}
            </a>
          </div>
          <Growl ref={growlRef} />
        </>
      ) : (
        <>
          <NavLink to="/login" className="login-btn fsi-14 mr-20">
            <i className="pi pi-sign-in" />
            <span className="pl-8">Đăng nhập</span>
          </NavLink>
        </>
      )}
    </Navbar>
  );
}

export default withRouter(Header);
