import { isEmpty } from 'lodash';
import CONSTANTS from '../constants/constant';

export default class User {
  constructor(initData?: any) {
    if (initData) {
      Object.assign(this, initData);
    }
  }

  id: number = 0;
  email: string = '';
  token: string = '';
  role: string = '';
  system_roles: string[] = [];
  chat_roles: string[] = [];
  staff_roles: string[] = [];
  app_roles: string[] = [];
  ppkd_roles: string[] = [];
  management_roles: string[] = [];
  insurance_roles: string[] = [];
  secretary_roles: string[] = [];
  regulation_roles_2: string[] = [];
  salary_roles_2: string[] = [];
  payment_roles: string[] = [];
  collateral_roles: string[] = [];
  ksd_roles: string[] = [];
  created_at: string = '';
  full_name: string = '';
  contact: string = '';
  address: string = '';
  password: string = '';
  avatar: string = '';
  projects: string[] = [];
  vic_menus: string[] = [];
  po_menus: string[] = [];
  po_ip_address: string = '';
  po_tai_xe_roles: string[] = [];
  po_don_hang_tai_xe_roles: string[] = [];
  po_lich_trinh_tai_xe_roles: string[] = [];
  po_ds_kh_roles: string[] = [];
  po_don_hang_kh_roles: string[] = [];
  po_van_hanh_roles: string[] = [];

  isLoggedIn(): boolean {
    return !isEmpty(this.token);
  }

  isAdmin(): boolean {
    try {
      return this.system_roles.indexOf(CONSTANTS.SYSTEM_ROLES.QUAN_TRI_HE_THONG) >= 0;
    } catch (e) {
      return false;
    }
  }

  hasRole(role: string): boolean {
    try {
      return (
        this.isAdmin() ||
        this.system_roles.indexOf(role) >= 0 ||
        this.chat_roles.indexOf(role) >= 0 ||
        this.staff_roles.indexOf(role) >= 0 ||
        this.app_roles.indexOf(role) >= 0 ||
        this.ppkd_roles.indexOf(role) >= 0 ||
        this.management_roles.indexOf(role) >= 0 ||
        this.insurance_roles.indexOf(role) >= 0 ||
        this.secretary_roles.indexOf(role) >= 0 ||
        this.regulation_roles_2.indexOf(role) >= 0 ||
        this.salary_roles_2.indexOf(role) >= 0 ||
        this.payment_roles.indexOf(role) >= 0 ||
        this.collateral_roles.indexOf(role) >= 0 ||
        this.ksd_roles.indexOf(role) >= 0
      );
    } catch (e) {
      return false;
    }
  }

  hasProject(project: string): boolean {
    try {
      return this.projects.indexOf(project) >= 0;
    } catch (e) {
      return false;
    }
  }

  hasVicMenu(menu: string): boolean {
    try {
      return this.vic_menus.indexOf(menu) >= 0;
    } catch (e) {
      return false;
    }
  }

  hasPOMenu(menu: string): boolean {
    try {
      return this.po_menus.indexOf(menu) >= 0;
    } catch (e) {
      return false;
    }
  }

  hasSystemRole(role: string): boolean {
    return this.system_roles?.indexOf(role) >= 0;
  }

  hasPaymentRole(role: string): boolean {
    return this.payment_roles?.indexOf(role) >= 0;
  }

  hasCollateralRole(role: string): boolean {
    return this.collateral_roles?.indexOf(role) >= 0;
  }

  hasSalaryRole(): boolean {
    return this.isAdmin() || this.salary_roles_2.length > 0;
  }

  hasProjectOneRole(role: string): boolean {
    return (
      this.po_tai_xe_roles?.indexOf(role) >= 0 ||
      this.po_don_hang_tai_xe_roles?.indexOf(role) >= 0 ||
      this.po_lich_trinh_tai_xe_roles?.indexOf(role) >= 0 ||
      this.po_ds_kh_roles?.indexOf(role) >= 0 ||
      this.po_don_hang_kh_roles?.indexOf(role) >= 0 ||
      this.po_van_hanh_roles?.indexOf(role) >= 0
    );
  }
}
