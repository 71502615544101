const CONSTANTS = {
  STORAGE_KEY: {
    DRIVER_USER: 'driver-user',
    REDIRECT: 'redirect',
    LOGIN_INFO: 'login-info',
    PRICE_QUOTE_DISMISS: 'price-quote-dismiss',
    INSTANT_DISMISS: 'instant-dismiss',
  },
  DATE_FORMAT: {
    LONG: 'DD/MM/YYYY HH:mm:ss',
    SHORT: 'DD/MM/YYYY',
    TIMEZONE: 'Asia/Ho_Chi_Minh',
  },
  TIME_FORMAT: 'HH:mm:ss',
  TIME_FORMAT_SHORT: 'HH:mm',
  SYSTEM_ROLES: {
    QUAN_TRI_HE_THONG: 'QUẢN TRỊ HỆ THỐNG',
    BAM_THANH_TOAN_CUA_VAN_HANH_DON_HANG: 'BẤM THANH TOÁN CỦA VẬN HÀNH ĐƠN HÀNG',
    QUAN_LY_GIO_CONG: 'QUẢN LÝ GIỜ CÔNG',
    BAO_MAT_TAI_XE_CHU_HANG: 'BẢO MẬT TÀI XẾ, CHỦ HÀNG',
    XEM_NOI_DUNG_CHINH_SUA_DON_HANG: 'XEM NỘI DUNG CHỈNH SỬA ĐƠN HÀNG',
  },
  CHAT_ROLES: {
    QUAN_LY_NHOM_CHAT_NOI_BO: 'QUẢN LÝ NHÓM CHAT NỘI BỘ',
  },
  STAFF_ROLES: {
    DS_TAI_XE: 'DS TÀI XẾ',
    XE_DAT_HANG: 'XE ĐẶT HÀNG',
    DS_CHU_HANG: 'DS CHỦ HÀNG',
    CHU_DAT_HANG: 'CHỦ ĐẶT HÀNG',
    DS_CHANH_XE: 'DS CHÀNH XE',
    CHANH_DAT_HANG: 'CHÀNH ĐẶT HÀNG',
    VAN_HANH_DON_HANG: 'VẬN HÀNH ĐƠN HÀNG',
    CHINH_SUA_HOP_DONG_DON_HANG: 'CHỈNH SỬA HỢP ĐỒNG ĐƠN HÀNG',
    LAM_LENH_THANH_TOAN_CHUYEN_KHOAN: 'LÀM LỆNH THANH TOÁN CHUYỂN KHOẢN',
    DS_BAO_HIEM: 'DS BẢO HIỂM',
    DON_HANG_BAO_HIEM: 'ĐƠN HÀNG BẢO HIỂM',
  },
  APP_ROLES: {
    TRUY_CAP_APP_TAI_XE: 'TRUY CẬP APP TÀI XẾ',
    TRUY_CAP_APP_CHU_HANG: 'TRUY CẬP APP CHỦ HÀNG',
  },
  PPKD_ROLES: {
    DON_HANG_NHANH: 'ĐƠN HÀNG NHANH',
    PHAN_VIEC_TAI_XE: 'PHÂN VIỆC TÀI XẾ',
    PHAN_VIEC_CHU_HANG: 'PHÂN VIỆC CHỦ HÀNG',
    XEM_THONG_KE_CUOC_GOI: 'XEM THỐNG KÊ CUỘC GỌI',
  },
  MANAGEMENT_ROLES: {
    CHINH_SUA_DU_LIEU_SAU_KHI_VAN_HANH_DON_HANG: 'CHỈNH SỬA DỮ LIỆU SAU KHI VẬN HÀNH ĐƠN HÀNG',
    DUA_SDT_NHAN_VIEN_LEN_TREN_APP: 'ĐƯA SĐT NHÂN VIÊN LÊN TRÊN APP',
    XEM_THONG_TIN_DAY_DU_KHI_VAN_HANH_DON_HANG: 'XEM THÔNG TIN ĐẦY ĐỦ KHI VẬN HÀNH ĐƠN HÀNG',
    BAM_NUT_VAN_CHUYEN_DON_HANG_TREN_VAN_HANH_DON_HANG: 'BẤM NÚT VẬN CHUYỂN ĐƠN HÀNG TRÊN VẬN HÀNH ĐƠN HÀNG',
    CHINH_SUA_THONG_TIN_TAI_XE: 'CHỈNH SỬA THÔNG TIN TÀI XẾ',
    CHINH_SUA_THONG_TIN_CHU_HANG: 'CHỈNH SỬA THÔNG TIN CHỦ HÀNG',
    DUYET_HUY_TUONG_TAC_TAI_XE: 'DUYỆT HỦY TƯƠNG TÁC TÀI XẾ',
    CHINH_SUA_MA_GIOI_THIEU_PHAN_TAI_XE: 'CHỈNH SỬA MÃ GIỚI THIỆU PHẦN TÀI XẾ',
    XEM_MA_CHEO_CHU_HANG_VA_TAI_XE: 'XEM MÃ CHÉO CHỦ HÀNG VÀ TÀI XẾ',
    QUAN_LY_FILE: 'QUẢN LÝ FILE',
    XEM_TAT_CA_DON_XE_DAT_HANG: 'XEM TẤT CẢ ĐƠN XE ĐẶT HÀNG',
    CAI_DAT_PHAN_DON: 'CÀI ĐẶT PHÂN ĐƠN',
  },
  INSURANCE_ROLES: {
    CAI_DAT_NGAY_GAN_HET_HAN_BAO_HIEM: 'CÀI ĐẶT NGÀY GẦN HẾT HẠN BẢO HIỂM',
    QUAN_LY_BAO_HIEM: 'QUẢN LÝ BẢO HIỂM',
    DUYET_DON_HANG_BAO_HIEM: 'DUYỆT ĐƠN HÀNG BẢO HIỂM',
  },
  SECRETARY_ROLES: {
    QUAN_LY_KE_TOAN: 'QUẢN LÝ KẾ TOÁN',
    QUYEN_DUYET_CHI_TIEN_GDDA: 'QUYỀN DUYỆT CHI TIỀN ( GDDA )',
    QUYEN_DUYET_CHI_TIEN_TPKD: 'QUYỀN DUYỆT CHI TIỀN ( TPKD )',
    QUYEN_DUYET_CHI_TIEN_KTT: 'QUYỀN DUYỆT CHI TIỀN ( KTT )',
    QUYEN_DUYET_CHI_TIEN_KTNB: 'QUYỀN DUYỆT CHI TIỀN ( KTNB )',
  },
  REGULATION_ROLES_2: {
    KHOA_THANH_TOAN_DON_HANG: 'KHOÁ THANH TOÁN ĐƠN HÀNG',
    QUAN_LY_PHAP_CHE: 'QUẢN LÝ PHÁP CHẾ',
    QUYEN_DUYET_VI_PHAM_PPPC: 'QUYỀN DUYỆT VI PHẠM ( PPPC )',
    QUYEN_DUYET_VI_PHAM_PPKD: 'QUYỀN DUYỆT VI PHẠM ( PPKD )',
    QUYEN_DUYET_VI_PHAM_TPKD: 'QUYỀN DUYỆT VI PHẠM ( TPKD )',
    QUYEN_DUYET_VI_PHAM_GDDA: 'QUYỀN DUYỆT VI PHẠM ( GDDA )',
  },
  SALARY_ROLES_2: {
    DUYET_GIO_LAM_VIEC: 'DUYỆT GIỜ LÀM VIỆC',
    XEM_BAO_CAO_GIO_VA_NGHI_PHEP: 'XEM BÁO CÁO GIỜ VÀ NGHỈ PHÉP',
    XEM_BANG_CHAM_CONG: 'XEM BẢNG CHẤM CÔNG',
    XEM_KPI_NHAN_HANG: 'XEM KPI NHẬN HÀNG',
    XEM_KPI_VAN_HANH: 'XEM KPI VẬN HÀNH',
    XEM_BANG_LUONG: 'XEM BẢNG LƯƠNG',
    CHINH_SUA_BANG_LUONG: 'CHỈNH SỬA BẢNG LƯƠNG',
  },
  PAYMENT_ROLES: {
    QUAN_LY_THANH_TOAN_1: 'QUẢN LÝ THANH TOÁN 1',
    QUAN_LY_THANH_TOAN_2: 'QUẢN LÝ THANH TOÁN 2',
    QUAN_LY_THANH_TOAN_3: 'QUẢN LÝ THANH TOÁN 3',
    NHAN_TIEN_CHU_HANG_THANH_TOAN: 'NHẬN TIỀN CHỦ HÀNG THANH TOÁN',
    HUY_LENH_NHAN_TIEN: 'HỦY LỆNH NHẬN TIỀN',
  },
  COLLATERAL_ROLES: {
    PHO_PHONG_KINH_DOANH: 'PHÓ PHÒNG KINH DOANH',
    TRUONG_PHONG_KINH_DOANH: 'TRƯỞNG PHÒNG KINH DOANH',
    KE_TOAN: 'KẾ TOÁN',
  },
  KIEM_SOAT_DON_ROLES: {
    GHIM_DON_TRA_SOAT: 'GHIM ĐƠN TRA SOÁT',
    AN_DON_HUY_TRA_SOAT: 'ẨN ĐƠN HỦY TRA SOÁT',
    QUAN_LY_DUYET_AN_DON_2: 'QUẢN LÝ DUYỆT ẨN ĐƠN 2',
    QUAN_LY_DUYET_AN_DON_3: 'QUẢN LÝ DUYỆT ẨN ĐƠN 3',
  },
  APP_PERMISSION_ROLES: {
    XEM_DANH_BA: 'XEM DANH BẠ',
    DON_HANG_NHANH: 'ĐƠN HÀNG NHANH',
  },
  SALARY_ROLES: {
    1: 'DUYỆT GIỜ LÀM VIỆC',
    2: 'XEM BÁO CÁO GIỜ VÀ NGHỈ PHÉP',
    3: 'XEM BẢNG CHẤM CÔNG',
    4: 'XEM KPI NHẬN HÀNG',
    5: 'XEM KPI VẬN HÀNH',
    6: 'XEM BẢNG LƯƠNG',
    7: 'CHỈNH SỬA BẢNG LƯƠNG',
  },
  REGULATION_ROLES: {
    1: 'PPPC',
    2: 'PPKD',
    3: 'TPKD',
    4: 'GDDA',
  },
  PROJECTS: {
    PHAN_MEM_VIC: 'PHẦN MỀM VIC',
    PROJECT_ONE: 'PROJECT ONE',
  },
  VIC_MENU_OPTIONS: {
    DS_TAI_XE: 'DS TÀI XẾ',
    XE_DAT_HANG: 'XE ĐẶT HÀNG',
    DS_CHANH: 'DS CHÀNH',
    CHANH_DAT_HANG: 'CHÀNH ĐẶT HÀNG',
    DS_CHU_HANG: 'DS CHỦ HÀNG',
    CHU_DAT_HANG: 'CHỦ ĐẶT HÀNG',
    VAN_HANH_DON_HANG: 'VẬN HÀNH ĐƠN HÀNG',
    HOP_DONG_DON_HANG: 'HỢP ĐỒNG ĐƠN HÀNG',
    THANH_TOAN_CHUYEN_KHOAN: 'THANH TOÁN CHUYỂN KHOẢN',
    KIEM_SOAT_DON_HANG: 'KIỂM SOÁT ĐƠN HÀNG',
    THAM_KHAO_GIA: 'THAM KHẢO GIÁ',
    DON_HANG_NHANH: 'ĐƠN HÀNG NHANH',
    KY_QUY: 'KÝ QUỸ',
    DS_BAO_HIEM: 'DS BẢO HIỂM',
    CSKH_BAO_HIEM: 'CSKH BẢO HIỂM',
    DON_HANG_BAO_HIEM: 'ĐƠN HÀNG BẢO HIỂM',
    PHAN_VIEC_CHU_HANG: 'PHÂN VIỆC CHỦ HÀNG',
    TH_GIAN_LAM_VIEC: 'TH/GIAN LÀM VIỆC',
    CHAT: 'CHAT',
    QUAN_LY_FILE: 'QUẢN LÝ FILE',
    BAO_CAO_THONG_KE_CONG_VIEC: 'BÁO CÁO THỐNG KÊ CÔNG VIỆC',
    PHAP_CHE: 'PHÁP CHẾ',
  },
  PO_MENU_OPTIONS: {
    DS_TAI_XE: 'DS TÀI XẾ',
    DAT_DON_CHO_TX: 'ĐẶT ĐƠN CHO TX',
    LICH_TRINH_TX: 'LỊCH TRÌNH TX',
    DS_KHACH_HANG: 'DS KHÁCH HÀNG',
    DAT_DON_CHO_KH: 'ĐẶT ĐƠN CHO KH',
    VAN_HANH_DON: 'VẬN HÀNH ĐƠN',
  },
  PO_TAI_XE_ROLES: {
    THEM_TAI_XE: 'THÊM TÀI XẾ',
    CHINH_SUA_THONG_TIN_TX: 'CHỈNH SỬA THÔNG TIN TÀI XẾ',
    NGHI_VIEC_TX: 'NGHỈ VIỆC TX',
    NGHI_PHEP_TX: 'NGHỈ PHÉP TX',
  },
  PO_DON_HANG_TAI_XE_ROLES: {
    TXE_HUY_DON: 'HỦY ĐƠN TÀI XẾ',
  },
  PO_LICH_TRINH_TAI_XE_ROLES: {
    DAT_DON_HANG_TAI_XE: 'ĐẶT ĐƠN HÀNG TÀI XẾ',
    CHINH_SUA_LICH_TRINH: 'CHỈNH SỬA LỊCH TRÌNH',
    CAI_DAT_GIA_GOC: 'CÀI ĐẶT GIÁ GỐC',
    CAI_DAT_GIA_KHUYEN_MAI: 'CÀI ĐẶT GIÁ KHUYẾN MÃI',
    XEM_LICH_SU_GIA_KHUYEN_MAI: 'XEM LỊCH SỬ GIÁ KHUYẾN MÃI',
  },
  PO_KHACH_HANG_ROLES: {
    DAT_DON_HANG_KHACH_HANG: 'ĐẶT ĐƠN HÀNG KHÁCH HÀNG',
  },
  PO_DON_HANG_KH_ROLES: {
    HUY_DON_KHACH_HANG: 'HỦY ĐƠN KHÁCH HÀNG',
    CHINH_SUA_DON_HANG: 'CHỈNH SỬA ĐƠN HÀNG',
    VAN_HANH_DON_HANG: 'VẬN HÀNH ĐƠN HÀNG',
  },
  PO_VAN_HANH_DON_ROLES: {
    HUY_DON_VAN_HANH: 'HỦY ĐƠN VẬN HÀNH',
    CHECK_IN: 'CHECK IN',
    CHECK_OUT: 'CHECK OUT',
  },
  SOCKET_EVENT: {
    MESSAGE_COUNT: 'message-count',
    NEW_MESSAGE: 'new-message',
    // NEW_INSTANT: 'new-instant',
    NEW_INSTANT_V2: 'new-instant-v2',
    NEW_INSTANT_OWNER: 'new-instant-owner',
    NEW_OWNER_ASSIGNMENT: 'new-owner-assignment',
    NEW_MATCHING_OWNER: 'new-matching-owner',
    NEW_MATCHING_ORDER: 'new-matching-order',
    NEW_PRICE_QUOTE: 'new-price-quotes',
    NEW_ORDER: 'new-order',
    INSTANT_RESERVE: 'instant-reserve',
    UPDATE_SUGGESTION: 'update-suggestion',
    UPDATE_MESSAGE: 'update-message',
  },
  LOCATION_DURATION: 3,
  RESERVED_TIME: 10,
  LOCKED_TIME_FOR_GROUP: 5,
  LOCKED_FOR_PRICE_QUOTE: 10,
  PAYMENT_TYPE: {
    COMPANY: 'CÔNG TY',
  },
  OWNER_STATUS: {
    CHO_XAC_MINH: 'CHỜ XÁC MINH',
    VAN_HANH_NGAY: 'VẬN HÀNH NGAY',
  },
  CUSTOMER_POSITION: {
    CTV: 'Cộng tác viên',
  },
};

export default CONSTANTS;
