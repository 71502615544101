import { Middleware, MiddlewareAPI } from 'redux';

import { AppActions, AppDispatch, AppState } from '..';
import { getSocketConnection } from '../../services/Socket';
import { SOCKET_IO_CONNECT, SOCKET_IO_DISCONNECT } from './types';
import {
  setNewChatMessage,
  setUpdateChatMessage,
  setUnreadMessageCount,
  setInstantSuggestions,
  setOwnerAssignments,
  setNewOrder,
  setNewOwner,
  setNewPriceQuotes,
  setV2Instants,
  setInstantReserve,
} from '../user/actions';
import CONSTANTS from '../../constants/constant';

export function createSocketMiddleware() {
  const middleware: Middleware = (store: MiddlewareAPI<AppDispatch, AppState>) => {
    return (next) => (action: AppActions) => {
      switch (action.type) {
        case SOCKET_IO_CONNECT:
          registerSocketEvents(store.dispatch, getSocketConnection());
          return;
        case SOCKET_IO_DISCONNECT:
          getSocketConnection().close(); // TODO of registered events also ???
          return;
        default:
          break;
      }
      return next(action);
    };
  };

  return middleware;
}

function registerSocketEvents(dispatch: AppDispatch, socket: any) {
  socket.removeAllListeners();

  socket.on(CONSTANTS.SOCKET_EVENT.MESSAGE_COUNT, (data: number) => {
    if (data > 0) {
    }
    dispatch(setUnreadMessageCount(data));
  });

  socket.on(CONSTANTS.SOCKET_EVENT.NEW_MESSAGE, (data: any) => {
    if (data) {
      console.log('NEW_MESSAGE', data);
      dispatch(setNewChatMessage(data));
    }
  });

  socket.on(CONSTANTS.SOCKET_EVENT.UPDATE_MESSAGE, (data: any) => {
    dispatch(setUpdateChatMessage(data));
  });

  socket.on(CONSTANTS.SOCKET_EVENT.NEW_INSTANT_V2, (data: any) => {
    console.log('NEW_INSTANT_V2', data);
    dispatch(setV2Instants(data));
  });

  socket.on(CONSTANTS.SOCKET_EVENT.NEW_INSTANT_OWNER, (data: any) => {
    console.log('NEW_INSTANT_OWNER', data);
    dispatch(setNewOwner(data));
  });

  socket.on(CONSTANTS.SOCKET_EVENT.NEW_ORDER, (data: any) => {
    console.log('NEW_ORDER', data);
    dispatch(setNewOrder(data));
  });

  socket.on(CONSTANTS.SOCKET_EVENT.NEW_PRICE_QUOTE, (data: any) => {
    console.log('NEW_PRICE_QUOTE', data);
    dispatch(setNewPriceQuotes(data));
  });

  socket.on(CONSTANTS.SOCKET_EVENT.INSTANT_RESERVE, (data: any) => {
    dispatch(setInstantReserve(data));
  });

  socket.on(CONSTANTS.SOCKET_EVENT.NEW_OWNER_ASSIGNMENT, (data: any) => {
    dispatch(setOwnerAssignments(data));
  });

  socket.on(CONSTANTS.SOCKET_EVENT.UPDATE_SUGGESTION, (data: any) => {
    dispatch(setInstantSuggestions(data));
  });

  socket.on('error', () => {
    window.location.reload();
  });

  socket.on('disconnect', () => {
    if (process.env.NODE_ENV !== 'development') {
      // window.location.reload();
    }
  });
}
